const initialState = () => {
  return {
    storeCode: null,
    customer: null,
    customerEmail: null,
    quote: null,
    quoteItems: [],
    lastAddedItem: {},
    quoteItemCount: 0,
    errors: []
  }
}

const getters = {
  isLoggedIn: (state): boolean => {
    return null !== state.customer
  },
  getCustomer: (state, getters): null | object => {
    return getters.isLoggedIn ? state.customer : null
  },
  getCustomerEmail: (state, getters): null | string => {
    return getters.isLoggedIn ? state.customer.email : state.customerEmail
  },
  defaultShippingAddress: (state, getters): null | object => {
    return getters.defaultShippingAddressId ? getters.customerAddresses.find((address: object) => address['id'] === getters.defaultShippingAddressId) : null
  },
  defaultBillingAddress: (state, getters): null | object => {
    return getters.defaultBillingAddressId ? getters.customerAddresses.find((address: object) => address['id'] === getters.defaultBillingAddressId) : null
  },
  defaultShippingAddressId: (state): null | number => {
    return state.customer?.default_shipping ? parseInt(state.customer?.default_shipping) : null
  },
  defaultBillingAddressId: (state): null | number => {
    return state.customer?.default_billing ? parseInt(state.customer?.default_billing) : null
  },
  customerAddresses: (state): object[] => {
    return state.customer?.addresses || []
  },
}

const mutations: object = {
  SET_STORE_CODE(state, storeCode: string): void {
    state.storeCode = storeCode
  },
  SET_CUSTOMER(state, customer: null | object): void {
    state.customer = customer
  },
  SET_CUSTOMER_EMAIL(state, customerEmail: string): void {
    state.customerEmail = customerEmail
  },
  SET_QUOTE(state, quote: object): void {
    state.quote = quote
    state.quoteId = quote['id']
    state.quoteItems = quote['items']
    state.quoteItemCount = quote['items_count']
  },
  SET_QUOTE_ITEMS(state, quoteItems): void {
    state.quoteItems = quoteItems
  },
  SET_LAST_ADDED_ITEM(state, quoteItem): void {
    state.lastAddedItem = JSON.parse(quoteItem)
  },
  SET_QUOTE_ITEM_COUNT(state, count): void {
    state.quoteItemCount = count
  },
  ADD_ERROR(state, error: object): void {
    state.errors.push(error)
  },
  RESET_ERRORS(state): void {
    state.errors = []
  }
}

const actions: object = {
  initialize({commit}, config: object): void {
    commit('SET_STORE_CODE', config['active_store_code'] || 'default')
    commit('CheckoutCart/SET_DEFAULT_CARRIER_DATA', {methodCode: config['default_method_code'], carrierCode: config['default_carrier_code']}, {root: true})
  },
  addError({commit}, error: object): void {
    commit('ADD_ERROR', error)
  },
  resetErrors({commit}): void {
    commit('RESET_ERRORS')
  }
}

const state = initialState()

const CheckoutGlobal: object = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutGlobal
