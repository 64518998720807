
  import {Component, Vue} from 'vue-property-decorator';

  @Component({
    name: 'search-form',
    components: {
      AutocompleteListItem: () => import('./search/AutocompleteListItem.vue')
    }
  })

  export default class extends Vue {
    $refs!: {
      autocompleteForm: HTMLFormElement,
      input: HTMLFormElement
    }

    private inputQuery: string = '';
    private typedQuery: string = '';

    protected autocompleteData: Array<object> = [];
    private _throttle: number;
    private autocompleteInitiated: boolean = false;
    private autocompleteLoading: boolean = false;
    private autocompleteRemove: boolean = false;
    private showAutocomplete: boolean = false;
    private mobileBreakpoint: number = 768;
    private windowWidth: number = window.innerWidth;
    private showSearchButton: boolean = true;
    private debounceDelay: number = 500;

    private created(): void {
      this.typedQuery = this.inputQuery;
    }

    private async triggerAutocomplete(): Promise<void> {
      clearTimeout(this._throttle);
      this._throttle = await window.setTimeout(async () => {
        const formData = new FormData(this.$refs.autocompleteForm);
        const searchQuery = formData.get('query');
        await this.fetchAutocompleteData(`${searchQuery}`);
      }, this.debounceDelay);
    }

    private async fetchAutocompleteData(searchQuery: string): Promise<void> {
      this.autocompleteRemove = searchQuery.length > 0;

      if (searchQuery.length < 4) {
        this.showSearchButton = this.windowWidth > this.mobileBreakpoint;
        this.showAutocomplete = false;
        this.autocompleteLoading = false;
        return;
      }

      try {
        this.autocompleteLoading = true;
        const {data} = await this.$solarClient.get('catalog/search/product/autocomplete/list', {
          params: {query: searchQuery}
        });

        this.inputQuery = searchQuery;
        this.autocompleteData = data;
      } catch (e) {
        console.error(e);
      } finally {
        this.autocompleteInitiated = true;
      }

      this.showAutocomplete = true;
      this.autocompleteLoading = false;
      this.showSearchButton = true;
    }

    private clearSearchInput(): void {
      this.inputQuery = '';
      this.$refs.input.value = '';
      this.$refs.input.focus();
      this.showAutocomplete = false;
      this.autocompleteRemove = false;
    }
  }
